import React from 'react';
import { Grid, useTheme } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Header, Body, Footer } from './components';

const Layout = () => {
    const theme = useTheme();
    return (
        <BrowserRouter>
            <Grid container>
                <Grid item xs={12}>
                    <Header />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        minHeight: '100vh',
                        pb: theme.spacing(4),
                    }}
                >
                    <Body />
                </Grid>
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            </Grid>
        </BrowserRouter>
    );
};

export default Layout;
